import Layout from 'components/Layout';
import React from 'react';
import seo from 'config/seo.json';

export default class PageNotFound extends React.Component {
	render() {
		return (
			<Layout title={seo.error.title} description={seo.default.description}>
				<h1>Page not found</h1>
				<a href="/">Go Back?</a>
			</Layout>
		);
	}
}
